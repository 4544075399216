import { SignChannel, SimulationType } from 'store/marketing/types'

export type MarketingData = {
  agentID?: string
  callCampaign?: string
  callPartnerAccount?: string
  campaignName?: string
  confirmationFromPortalRegistration?: boolean
  eanCaptureType?: EanCaptureType
  emailConfirmationFlow?: boolean
  eventID?: string
  eventName?: string
  gclid?: string
  noEanFlow?: boolean
  optinPoint?: OptinPoint
  partnerID?: string
  sfUserAlias?: string
  shiftID?: string
  signChannel?: SignChannel
  simulationSalesOffice?: string
  simulationType?: SimulationType
  sourceType?: SourceType
}

export type MarketingParams = {
  sourceType?: string
  simulationSo?: string
}

// ENUMS

export enum EanCaptureType {
  NO_LOOKUP_DONE = 'No look-up done',
  NO_EAN_PROCESS = 'No EAN process',
  NOT_FOUND = 'Not found - Manual filled',
  OVERWRITTEN = 'Overwritten by customer',
  PREFILLED = 'Prefilled'
}

export enum OptinPoint {
  WEBSITE_SIMULATION = 'Website Simulation',
  WEBSITE_REGISTRATION = 'Website Registration'
}

export enum SourceType {
  PUSH = 'Push', // Sales
  PULL = 'Pull'
}
