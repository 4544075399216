import { CounterTypes, EnergySuppliers, ElectricityAndGasPrices, MeterUsage, SimulatePriceResponse } from 'features/simulation/types'
import { Language } from './language'
import { EnergyUsageGrade } from './usages'
import { MarketingData } from './marketing-params'
import { ELProduct, NGProduct } from './product-data'
import { ProspectAndCustomerProducer } from './producer'
import { PromoCode, ReferralCode } from './promo-referral-codes'
import { Situation } from 'features/registration/types'
import { ContactMoment, PreviousInhabitant } from 'store/customer/types'

export type Address = {
  countryCode: 'BE'
  postalCode: string
  townCode: number
  townName: string
  streetName: string
  streetNumber: string
  streetBox: string
}

export type WarmProspectData = {
  firstName: string
  lastName: string
  email: string
  language: Language
  flow: WarmProspectFlow

  // Festival specific fields
  tieBreaker?: number
}

export type CreateProspectReturnType = {
  opportunityId: string
  accepted: boolean
}

export type CreateCustomerData = GeneralProspectAndCustomerData & {
  company?: {
    name: string
    vatApplication: boolean
    enterpriseNumber: string
    legalForm: LegalForm
  }
  contact: {
    contactMode: DeliveryMode
    email: string
    phoneMobile: string
  }
  contractStartDate: string // ISO date format
  correspondenceAddress: Address
  deliveryAddress: Address
  dynamicTariff: boolean
  invoicing: {
    paymentMethod: string
    ibanNumber: string
  }
  knowsMeterDetails: boolean
  meterInfo: {
    electricity: {
      EAN: string
      exclEAN: string
      indexDay: string
      indexNight: string
      indexExcl: string
      meterNumber: string
    }
    gas: {
      EAN: string
      index: string
      meterNumber: string
    }
    meterReadingMonth?: number
  }
  opportunityId: string
  person: {
    firstName: string
    lastName: string
    birthDate: string
  }
  previousSupplier?: EnergySuppliers
  priceListUrl: FuelPricelistUrls
  producer: ProspectAndCustomerProducer
  signatureDataUrl?: string
  situation: Situation
  uploadDataUrl?: string
}

type GeneralProspectAndCustomerData = {
  boltGoPrices?: ElectricityAndGasPrices
  counterType: CounterTypes
  energyUsageGrade?: EnergyUsageGrade
  fixedPrices?: ElectricityAndGasPrices
  forcedAmount?: number
  language: Language
  marketing: MarketingData
  meterUsage: MeterUsage
  price: SimulatePriceResponse['price']
  products: {
    electricity: ELProduct
    gas?: NGProduct
  }
  promoCode?: PromoCode
  referralCode?: ReferralCode
  solarKva?: number
  hasSolarPanels: boolean
  walloniaSolarPanelsSince2024?: boolean
  previousInhabitant?: PreviousInhabitant
}

export type CreateProspectData = GeneralProspectAndCustomerData & {
  companyName?: string
  contactMoment?: ContactMoment
  creditCheck?: boolean
  email: string
  enterpriseNumber?: string
  firstName: string
  isCompany: boolean
  lastName: string
  mobilePhone: string
  opportunityId?: string
  producer?: ProspectAndCustomerProducer
  zipcode: number
}

export type FuelPricelistUrls = {
  electricity: string
  gas: string
}

// ENUMS

export enum ClientType {
  PROFESSIONAL = 'Professional',
  RESIDENTIAL = 'Residential'
}

export enum DeliveryMode {
  EMAIL = 'Email',
  POST = 'Post'
}

export enum LegalForm {
  ACP_VME = 'ACP - VME',
  ASBL_VZW = 'ASBL - VZW',
  INDIVIDUAL = 'Entreprise Individuelle - Eenmanszaak',
  SA_NV = 'SA - NV',
  SCOMM_COMMV = 'SComm - CommV',
  SCRL_CVBA = 'SCRL - CVBA',
  SCS_GCV = 'SCS - GCV',
  SNC_VOF = 'SNC - VOF',
  SPRLU_EBVBA = 'SPRLU - EBVBA',
  SPRL_BVBA = 'SPRL - BVBA',
  SRL_BV = 'SRL - BV'
}

export enum StageName {
  CLOSED_LOST = 'Closed Lost',
  CLOSED_WON = 'Closed Won',
  IN_PROGRESS = 'In Progress',
  NEEDS_ANALYSIS = 'Needs Analysis',
  NEGOTIATION = 'Negotiation',
  NEW = 'New',
  NO_EAN = 'No EAN',
  PARKED = 'Parked',
  PROPOSAL = 'Proposal',
  QUALIFICATION = 'Qualification',
  TO_BE_CONFIRMED = 'To Be Confirmed'
}

export enum WarmProspectFlow {
  FESTIVAL = 'festival',
  NEWSLETTER = 'newsletter'
}
