import { BannerTypes } from 'components/banner/types'
import { BOLT_ENERGIE_CARE_PARTNER_ID, SessionStorageKeys } from 'constants/constants'
import { store } from 'store'
import Router from 'next/router'
import { updateMarketingData } from 'store/marketing/slice'
import { MarketingDataKeys, SignChannel, SimulationType } from 'store/marketing/types'
import { CookieKeys } from 'types/cookies'
import { createCookie } from './cookies'
import { InputGroupKeys, MeterDetailsFieldKeys, PersonalDataFieldKeys, SimulationFieldKeys } from 'store/customer/enums'
import { Language } from 'types/language'
import { getDataByOpportunityId, setPromoOrReferralCodeInStore } from './customerFlow'
import { Product } from 'types/product-data'
import { routes } from 'utils/routes'
import mixpanel from 'mixpanel-browser'
import { initAbTesting, trackEvent } from 'utils/tracking'
import { boltApi } from 'store/api/boltApi'
import { updateOpportunityId } from 'store/app/slice'
import { selectProposition, updateBoltGoPrices, updateInput } from 'store/customer/slice'
import { BOLT_GO_PRICES } from 'constants/customerFlow'
import QueryString, { parse } from 'qs'
import { UrlSearchParams } from 'types/url'
import { AddressFields } from 'components/form-fields/address-section/types'
import { CommonTrackingEvents } from 'types/tracking'

/**
 * Executes all needed tasks for startup
 */
export const startUp = (): void => {
  initStoreSubscription()

  // Add needed data to the store
  store.dispatch(boltApi.endpoints.getProducers.initiate())

  // Fetch the URL params
  const parsedSearchQuery = parse(window.location.search, { ignoreQueryPrefix: true })

  // Check and save all URL params
  checkAndSaveUrlParameters(parsedSearchQuery)

  // Set the registration language based on the current language
  store.dispatch(
    updateInput({
      group: InputGroupKeys.PERSONAL_DATA,
      key: PersonalDataFieldKeys.LANGUAGE,
      value: Router.locale || Language.DUTCH
    })
  )

  // Analyze the URL params
  const isCareUser = (parsedSearchQuery['partner_id'] as string) === BOLT_ENERGIE_CARE_PARTNER_ID
  const isPartner = !!parsedSearchQuery['partner_id']
  const sourceType = parsedSearchQuery['source_type'] || store.getState().marketing.sourceType
  const disableABTesting = (sourceType as string)?.toLowerCase() === 'push' || isCareUser

  // Initialize AB testing
  const assignedGroups = initAbTesting(disableABTesting)

  // Track assigned group & sourceType
  mixpanel.register({
    careUser: isCareUser,
    partner: isPartner,
    sourceType,
    ...(!disableABTesting && !!assignedGroups && assignedGroups)
  })
}

// USED STARTUP FUNCTIONS

/**
 * Checks and saves all the url paramaters if necessary
 *
 * @docs: https://www.notion.so/boltenergie/Website-URL-parameters-15f5f86ef80e8084911added16e26176?pvs=4
 */
const checkAndSaveUrlParameters = async (parsedSearchQuery: QueryString.ParsedQs): Promise<void> => {
  // CHECK ALL NEEDED PARAMS (alphabetically ordered)
  if (parsedSearchQuery['adjustable_product'])
    store.dispatch(
      updateMarketingData({ key: MarketingDataKeys.ADJUSTABLE_PRODUCT, value: parsedSearchQuery['adjustable_product'] === 'true' })
    )

  if (parsedSearchQuery['available_products']) {
    const availableProducts = Array.isArray(parsedSearchQuery['available_products'])
      ? (parsedSearchQuery['available_products'] as Product[])
      : ((parsedSearchQuery['available_products'] as string)?.trim()?.split(',') as Product[])

    const filteredAvailableProducts = availableProducts.filter((product) => product)

    if (availableProducts?.length > 0) {
      store.dispatch(updateMarketingData({ key: MarketingDataKeys.AVAILABLE_PRODUCTS, value: filteredAvailableProducts }))
    }
  }

  if (parsedSearchQuery['agent_id'])
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.AGENT_ID, value: parsedSearchQuery['agent_id'] as string }))

  if (parsedSearchQuery['banner'])
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.BANNER, value: parsedSearchQuery['banner'] as string }))

  if (parsedSearchQuery['call_campaign'])
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.CALL_CAMPAIGN, value: parsedSearchQuery['call_campaign'] as string }))

  if (parsedSearchQuery['call_partner_account'])
    store.dispatch(
      updateMarketingData({ key: MarketingDataKeys.CALL_PARTNER_ACCOUNT, value: parsedSearchQuery['call_partner_account'] as string })
    )

  if (parsedSearchQuery['campaign_name'])
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.CAMPAIGN_NAME, value: parsedSearchQuery['campaign_name'] as string }))

  if (parsedSearchQuery[UrlSearchParams.DP_EAN_ELECTRICITY]) {
    store.dispatch(
      updateInput({
        group: InputGroupKeys.METER_DETAILS,
        key: MeterDetailsFieldKeys.ELECTRICITY,
        value: {
          ...store.getState().customer.inputs[InputGroupKeys.METER_DETAILS][MeterDetailsFieldKeys.ELECTRICITY],
          ean: parsedSearchQuery[UrlSearchParams.DP_EAN_ELECTRICITY] as string
        }
      })
    )
  }

  if (parsedSearchQuery[UrlSearchParams.DP_EAN_GAS]) {
    store.dispatch(updateInput({ group: InputGroupKeys.PERSONAL_DATA, key: PersonalDataFieldKeys.NEEDS_GAS, value: true }))
    store.dispatch(
      updateInput({
        group: InputGroupKeys.METER_DETAILS,
        key: MeterDetailsFieldKeys.GAS,
        value: {
          ...store.getState().customer.inputs[InputGroupKeys.METER_DETAILS][MeterDetailsFieldKeys.GAS],
          ean: parsedSearchQuery[UrlSearchParams.DP_EAN_GAS] as string
        }
      })
    )
  }

  if (
    parsedSearchQuery[UrlSearchParams.DP_STREET] &&
    parsedSearchQuery[UrlSearchParams.DP_NUMBER] &&
    parsedSearchQuery[UrlSearchParams.DP_ZIP] &&
    parsedSearchQuery[UrlSearchParams.DP_CITY]
  ) {
    store.dispatch(
      updateInput({
        group: InputGroupKeys.PERSONAL_DATA,
        key: PersonalDataFieldKeys.DELIVERY_ADDRESS,
        value: {
          [AddressFields.STREET]: parsedSearchQuery[UrlSearchParams.DP_STREET] as string,
          [AddressFields.NUMBER]: parsedSearchQuery[UrlSearchParams.DP_NUMBER] as string,
          [AddressFields.BOX]: parsedSearchQuery[UrlSearchParams.DP_BOX]
            ? (parsedSearchQuery[UrlSearchParams.DP_BOX] as string)
            : undefined,
          [AddressFields.POSTAL_CODE]: parsedSearchQuery[UrlSearchParams.DP_ZIP] as string,
          [AddressFields.TOWN_NAME]: parsedSearchQuery[UrlSearchParams.DP_CITY] as string
        }
      })
    )
  }

  if (parsedSearchQuery['ean_code_mandatory'])
    store.dispatch(
      updateMarketingData({ key: MarketingDataKeys.EAN_CODE_MANDATORY, value: parsedSearchQuery['ean_code_mandatory'] === 'true' })
    )

  if (parsedSearchQuery['event_name'])
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.EVENT_NAME, value: parsedSearchQuery['event_name'] as string }))

  if (parsedSearchQuery['event_id'])
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.EVENT_ID, value: parsedSearchQuery['event_id'] as string }))

  if (parsedSearchQuery['execute_credit_check'])
    store.dispatch(
      updateMarketingData({ key: MarketingDataKeys.EXECUTE_CREDIT_CHECK, value: parsedSearchQuery['execute_credit_check'] !== 'false' })
    )

  if (parsedSearchQuery['gclid']) {
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.GCLID, value: parsedSearchQuery['gclid'] as string }))
    createCookie(CookieKeys.GCLID, parsedSearchQuery['gclid'] as string, 90)
  }

  if (parsedSearchQuery['no_optin_one'])
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.NO_OPTIN_ONE, value: parsedSearchQuery['no_optin_one'] === 'true' }))

  if (parsedSearchQuery['opportunityId']) {
    const opportunityId = parsedSearchQuery['opportunityId'] as string

    store.dispatch(updateOpportunityId({ opportunityId }))

    // Should always be executed after opportunityId is stored as we need the opportunityId to fetch the simulation params from the API
    if (parsedSearchQuery['fetchSimulation'] === 'true') {
      getDataByOpportunityId(Router.locale as Language)
    }
  }

  if (parsedSearchQuery['partner_id'])
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.PARTNER_ID, value: parsedSearchQuery['partner_id'] as string }))

  if (parsedSearchQuery['ask_previous_supplier']) {
    const value = parsedSearchQuery['ask_previous_supplier'] === 'true'
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.PREVIOUS_SUPPLIER, value }))
  }

  if (parsedSearchQuery['promocode']) {
    const referralCodeFromQueryString = String(parsedSearchQuery['promocode'])

    try {
      const { code, valid } = await setPromoOrReferralCodeInStore(referralCodeFromQueryString)

      if (valid) {
        if (parsedSearchQuery['producer-referral'] === '1') {
          const bannerNL = `Stap over op lokale stroom en krijg €50 korting met de promocode ${code}`
          const bannerFR = `Passez à l'électricité locale et bénéficiez d'une réduction de 50 euros avec le code promo ${code}`

          store.dispatch(
            updateMarketingData({ key: MarketingDataKeys.BANNER, value: Router.locale === Language.DUTCH ? bannerNL : bannerFR })
          )
        }
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  if (parsedSearchQuery[UrlSearchParams.REFERRAL_CODE]) {
    const referralCodeFromQueryString = String(parsedSearchQuery[UrlSearchParams.REFERRAL_CODE])

    try {
      const { valid } = await setPromoOrReferralCodeInStore(referralCodeFromQueryString)

      if (valid) {
        // !! We also need to set the cashback promocode (= VIAVIA) because of the referral campaign !!
        setPromoOrReferralCodeInStore('VIAVIA')
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  if (parsedSearchQuery['reset_button'])
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.RESET_BUTTON, value: parsedSearchQuery['reset_button'] === 'true' }))

  if (parsedSearchQuery[UrlSearchParams.SALESFORCE_USER_ALIAS]) {
    store.dispatch(
      updateMarketingData({
        key: MarketingDataKeys.SALESFORCE_USER_ALIAS,
        value: parsedSearchQuery[UrlSearchParams.SALESFORCE_USER_ALIAS] as string
      })
    )
  }

  if (parsedSearchQuery['shift_id'])
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.SHIFT_ID, value: parsedSearchQuery['shift_id'] as string }))

  if (parsedSearchQuery['sign_channel']) {
    const signChannels = Array.isArray(parsedSearchQuery['sign_channel'])
      ? (parsedSearchQuery['sign_channel'] as SignChannel[])
      : ([parsedSearchQuery['sign_channel']] as SignChannel[])

    store.dispatch(
      updateMarketingData({
        key: MarketingDataKeys.SIGN_CHANNEL,
        value: signChannels
      })
    )
  }

  if (parsedSearchQuery['simulation_type']) {
    let simulationTypes = (parsedSearchQuery['simulation_type'] as SimulationType[]) || [SimulationType.PRICING_SIMULATION]

    // Ensure simulationType is an array
    if (!Array.isArray(simulationTypes)) {
      simulationTypes = [simulationTypes]
    }

    const hasBoltGo = simulationTypes.includes(SimulationType.BOLT_GO)

    // Remove other simulation types than Bolt GO if present
    if (simulationTypes.length > 1 && hasBoltGo) {
      simulationTypes = simulationTypes.filter((type) => type === SimulationType.BOLT_GO)
    }

    // Save the possible simulation types to the marketing store
    store.dispatch(
      updateMarketingData({
        key: MarketingDataKeys.SIMULATION_TYPE,
        value: simulationTypes
      })
    )

    // Set the initial (chosen) simulation type in the simulation store
    // Default 'Pricing Simulation' if available, otherwise the first one
    store.dispatch(
      updateInput({
        group: InputGroupKeys.SIMULATION,
        key: SimulationFieldKeys.CHOSEN_SIMULATION_TYPE,
        value: simulationTypes.includes(SimulationType.PRICING_SIMULATION) ? SimulationType.PRICING_SIMULATION : simulationTypes[0]
      })
    )

    // Check if Bolt Go
    if (hasBoltGo) {
      // Set the selected product
      store.dispatch(selectProposition({ productType: Product.GO }))

      // Set the Bolt Go prices
      store.dispatch(updateBoltGoPrices(BOLT_GO_PRICES))

      // Redirect to the registration loading page
      Router.push(routes(Router.locale).registrationLoading)
    }
  }

  if (parsedSearchQuery[UrlSearchParams.SIMULATION_SALES_OFFICE]) {
    const simulationSo = parsedSearchQuery[UrlSearchParams.SIMULATION_SALES_OFFICE] as string
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.SIMULATION_SO, value: simulationSo }))

    // Set BBP banner if simulation_so url param equals the BBP string
    if (simulationSo === 'Brussels Beer Project')
      store.dispatch(updateMarketingData({ key: MarketingDataKeys.BANNER_TYPE, value: BannerTypes.BRUSSELS_BEER_PROJECT }))
  }

  if (parsedSearchQuery['source_type'])
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.SOURCE_TYPE, value: parsedSearchQuery['source_type'] as string }))

  if (parsedSearchQuery['subscribed'] === '1') createCookie(CookieKeys.SUBSCRIBED, 'true')

  if (parsedSearchQuery['trigger_events'] === '0')
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.TRIGGER_EVENTS, value: parsedSearchQuery['trigger_events'] as string }))

  if (parsedSearchQuery['unlock']) createCookie(CookieKeys.UNLOCK, parsedSearchQuery['unlock'] as string)

  // REDIRECTS - always at the end
  if (parsedSearchQuery['simulation'] && parsedSearchQuery['simulation'] === '1') {
    // Track 'Simulation Started' event @Semetis
    trackEvent(CommonTrackingEvents.SIMULATION_STARTED)
    Router.push(routes(Router.locale).simulation)
  }
}

/**
 * Initializes the store subscription (executes given subscribe function everytime the store changes)
 * Saves marketing store to window session storage
 */
const initStoreSubscription = (): void => {
  store.subscribe(() => {
    // Check if window & session storage are defined
    if (window && window.sessionStorage) {
      // Fetch the auth store & save it to the session storage
      const { marketing } = store.getState()
      window.sessionStorage.setItem(SessionStorageKeys.MARKETING, JSON.stringify(marketing))
    }
  })
}
