export type GetRoute = {
  [key in RouteKeys]: string
}

export type RouteKeys =
  | 'about'
  | 'becomeProducer'
  | 'becomeProducerForm'
  | 'blog'
  | 'blogArticle'
  | 'boltFix'
  | 'boltGo'
  | 'brusselsAirport'
  | 'careerDetail'
  | 'careers'
  | 'dynamicPricing'
  | 'encyclopedia'
  | 'encyclopediaArticle'
  | 'encyclopediaCategory'
  | 'encyclopediaSubCategory'
  | 'energyGarden'
  | 'energyGardenConfirmation'
  | 'energyGardenContact'
  | 'energyGardenFaq'
  | 'energyGardenHowItWorks'
  | 'energyGardenInfoSession'
  | 'energyGardenOrder'
  | 'energyGardenTerms'
  | 'energyGardens'
  | 'festivals'
  | 'faq'
  | 'home'
  | 'honestEnergy'
  | 'miniGenerators'
  | 'move'
  | 'noEan'
  | 'oldTerms'
  | 'positiveEnergy'
  | 'priceList'
  | 'pricing'
  | 'privacy'
  | 'producerInfo'
  | 'producers'
  | 'projectDetail'
  | 'projects'
  | 'referral'
  | 'registration'
  | 'registrationLoading'
  | 'settlement'
  | 'simulation'
  | 'springCampaign'
  | 'terms'

export enum RouteVariables {
  ARTICLE = ':article',
  CATEGORY = ':category',
  SUB_CATEGORY = ':subCategory',
  SLUG = ':slug',
  VERSION = ':version'
}
