import { Producer } from 'types/producer'
import type { OverviewStepProps, PersonalDataStepProps } from 'features/registration/steps/types'

import { CmsReferralAction } from 'types/cms/collection-types'

// COMPONENT PROPS

export type RegistrationProps = {
  hideHeader?: boolean
  producer: Producer
  activeAction?: CmsReferralAction
} & Pick<OverviewStepProps, 'overrideNextStep'> &
  Pick<PersonalDataStepProps, 'overridePreviousStep'>

// ENUMS

export enum PaymentMode {
  DOMICILIATION = 'Domiciliation',
  TRANSFER = 'Transfer'
}

export enum Situation {
  SWITCH = 'Supplier Switch',
  MOVE = 'Move',
  NEW_CONNECTION = 'New Connection'
}

export enum RegistrationSteps {
  PERSONAL_DATA = 'Personal Data',
  METER_DETAILS = 'Meter Details',
  BILLING = 'Billing',
  OVERVIEW = 'Overview',
  CONFIRMATION = 'Confirmation'
}

// TYPES

export type CreditCheck = {
  executed: boolean
  accepted?: boolean
}

export type Month = {
  i18nKey: string
  defaultValue: string
}

export type StartDateRange = {
  min: string // ISO
  max: string // ISO
  initial: string // ISO
}
