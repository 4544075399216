import { BannerTypes, PredefinedBanner } from 'components/banner/types'

export const PredefinedBanners: Record<BannerTypes | string, PredefinedBanner> = {
  [BannerTypes.BRUSSELS_BEER_PROJECT]: {
    color: 'yellow',
    main: {
      defaultCopy: 'Registreer je en claim je gratis Circular Beer Box',
      i18nKey: 'banner.main'
    },
    ns: 'brusselsBeerProject',
    small: {
      defaultCopy: 'Nadat je je registratie bevestigd hebt, ontvang je een e-mail over de verzending van de box.',
      i18nKey: 'banner.small'
    }
  },

  [BannerTypes.VIA_VIA]: {
    color: 'yellow',
    main: {
      defaultCopy: 'Jij krijgt €30 korting bij je overstap dankzij de doorverwijzing van jouw vriend!',
      i18nKey: 'viralloopsnotification'
    },
    ns: 'home'
  },

  [BannerTypes.SIMULATION_DOWN]: {
    color: 'yellow',
    main: {
      defaultCopy: 'Door een technisch probleem bij onze externe partner kan je momenteel geen voorstellen berekenen.',
      i18nKey: 'simulationDown.title'
    },
    small: {
      defaultCopy: 'Onze excuses hiervoor. Probeer het later vandaag nog een keer.',
      i18nKey: 'simulationDown.apologies'
    },
    ns: 'common'
  }
}
