import { FC, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import { StayInformedFields } from './types'
import classNames from 'classnames'
import styles from './StayInformed.module.scss'
import { InputGroupKeys, PersonalDataFieldKeys } from 'store/customer/enums'
import { useRouter } from 'next/router'
import { Button } from '@boltenergy-be/design-system'
import { hasNoNumber } from 'utils/helpers'
import { Language } from 'types/language'
import { updateInput } from 'store/customer/slice'
import { useStoreDispatch } from 'hooks/store'
import { WarmProspectFlow } from 'types/customer'
import axios from 'axios'

export const StayInformed: FC = () => {
  // Redux store
  const dispatch = useStoreDispatch()

  // Next router
  const { locale } = useRouter()

  // i18n
  const { t } = useTranslation(['common', 'validation', 'commonFormFields'])

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<StayInformedFields>({ mode: 'onChange' })

  // Local state
  const [loading, setLoading] = useState<boolean>(false)
  const [sent, setSent] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  /**
   * Handles the submit after validation
   *
   * @param data
   */
  const onSubmit = async (data: StayInformedFields) => {
    setSent(false)
    setError(false)
    setLoading(true)

    try {
      const { firstName, lastName, email } = data

      // Update the fields in the registration state (firstname, lastname & email are also used there)
      dispatch(updateInput({ group: InputGroupKeys.PERSONAL_DATA, key: PersonalDataFieldKeys.FIRSTNAME, value: firstName }))
      dispatch(updateInput({ group: InputGroupKeys.PERSONAL_DATA, key: PersonalDataFieldKeys.LASTNAME, value: lastName }))
      dispatch(updateInput({ group: InputGroupKeys.PERSONAL_DATA, key: PersonalDataFieldKeys.EMAIL, value: email }))

      // Create a newsletter prospect
      await axios.post('/api/prospects/warm', {
        email,
        firstName,
        language: locale as Language,
        lastName,
        flow: WarmProspectFlow.NEWSLETTER
      })

      setSent(true)
    } catch (e) {
      setError(true)
    }

    setLoading(false)
  }

  return (
    <form className={styles['stay-informed']} onSubmit={handleSubmit(onSubmit)}>
      <div className={classNames('form-group', styles['form-group'])}>
        <div className={classNames('form-input', styles['form-input'])}>
          <label
            htmlFor="firstName"
            className={classNames('label mb-100', {
              'is-red': errors.firstName && errors.firstName.type === 'required'
            })}
          >
            {t('commonFormFields:firstName', 'Voornaam')}
          </label>
          <input
            id="firstName"
            type="text"
            className={classNames(styles.input, 'input', 'mb-400')}
            {...register('firstName', {
              maxLength: 40,
              minLength: 2,
              required: true,
              validate: (value: string) => hasNoNumber(value)
            })}
          />
          {errors && errors.firstName && (
            <span className="is-block mb-400 is-red">
              {errors.firstName.type === 'required' && t('validation:required')}
              {errors.firstName.type === 'minLength' &&
                t('validation:min.string', {
                  attribute: t('commonFormFields:firstName', 'Voornaam'),
                  length: 2
                })}
              {errors.firstName.type === 'maxLength' &&
                t('validation:max.string', {
                  attribute: t('commonFormFields:firstName', 'Voornaam'),
                  length: 40
                })}
              {errors.firstName.type === 'validate' &&
                t('validation:noNumbers', {
                  attribute: t('commonFormFields:firstName', 'Voornaam')
                })}
            </span>
          )}
        </div>

        <div className={classNames('form-input', styles['form-input'])}>
          <label
            htmlFor="lastName"
            className={classNames('label mb-100', {
              'is-red': errors.lastName && errors.lastName.type === 'required'
            })}
          >
            {t('commonFormFields:lastName', 'Achternaam')}
          </label>
          <input
            id="lastName"
            type="text"
            className={classNames(styles.input, 'input', 'mb-400')}
            {...register('lastName', {
              maxLength: 80,
              required: true,
              validate: (value: string) => hasNoNumber(value)
            })}
          />
          {errors && errors.lastName && (
            <span className="is-block mb-400 is-red">
              {errors.lastName.type === 'required' && t('validation:required')}
              {errors.lastName.type === 'maxLength' &&
                t('validation:max.string', {
                  attribute: t('commonFormFields:lastName', 'Achternaam'),
                  length: 80
                })}
              {errors.lastName.type === 'validate' &&
                t('validation:noNumbers', {
                  attribute: t('commonFormFields:lastName', 'Achternaam')
                })}
            </span>
          )}
        </div>
      </div>

      <div className={classNames('form-input', styles['form-input'])}>
        <label
          htmlFor="email"
          className={classNames('label mb-100', {
            'is-red': errors.email && errors.email.type === 'required'
          })}
        >
          {t('commonFormFields:email.label', 'Je e-mailadres')}
        </label>
        <input id="email" type="email" className={classNames(styles.input, 'input', 'mb-400')} {...register('email', { required: true })} />
      </div>

      <label
        className={classNames('mb-400', {
          'is-red': errors.permission && errors.permission.type === 'required'
        })}
      >
        <input type="checkbox" {...register('permission', { required: true })} />
        &nbsp;
        {t('subscribePermission', 'Ik geef Bolt toestemming om mij e-mails te sturen.')}
      </label>

      <Button loading={loading} disabled={sent || error} isFullwidthMobile>
        {t('subscribeYourself', 'Schrijf je in')}
      </Button>

      {sent && <p className="success mb-400 mb-none">{t('subscribedSuccess')}</p>}

      {error && (
        <p className="error is-red mb-400 mb-none">
          {t('subscribedFailed', 'Er ging iets mis! Onze developer lost dit zo snel mogelijk op. Probeer straks nog eens.')}
        </p>
      )}
    </form>
  )
}

export default StayInformed
