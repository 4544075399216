export enum ContentBlocks {
  RICH_TEXT = 'content-blocks.rich-text',
  IMAGE = 'content-blocks.image',
  QUOTE = 'content-blocks.quote',
  GOOD_TO_KNOW = 'content-blocks.good-to-know',
  VIMEO = 'content-blocks.vimeo',
  VIDEO = 'content-blocks.video'
}

export enum ProjectSections {
  VIDEO_SECTION = 'projects.video-section',
  IMAGE_SECTION = 'projects.image-section',
  PARALLAX_QUOTE = 'projects.parallax-quote',
  PARTNERS_SECTION = 'projects.partners',
  NUMBER_SECTION = 'projects.number-section',
  EXTERNAL_CARD_SECTION = 'projects.external-card-section'
}

export enum ProjectFooters {
  SOCIAL_CTA = 'projects.social-cta',
  SIMULATION_CTA = 'projects.simulation-cta',
  NEWS_CTA = 'projects.news-cta'
}

export enum CmsBackgroundColors {
  WHITE = 'white',
  LIGHT_BLUE = 'light blue'
}

export enum CmsAlignments {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export enum ImageStyles {
  REGULAR = 'regular',
  CARD = 'card'
}

export enum CmsArticleTypes {
  ENCYCLOPEDIA = 'encyclopedia',
  BLOG_INTERNAL = 'blogInternal',
  BLOG_EXTERNAL = 'blogExternal'
}

export enum CmsPages {
  ENCYCLO_CATEGORY = 'encyclopedia-category',
  ENCYCLO_SUB_CATEGORY = 'encyclopedia-sub-category',
  ENCYCLO_ARTICLE = 'encyclopedia-article',
  BLOG_ARTICLE = 'blog-article',
  FAQ_ARTICLE = 'faq-article',
  FAQ_CATEGORY = 'faq-category',
  TEAM_MEMBER = 'team-member',
  JOB_OFFER = 'job-offer',
  PROJECT = 'project',
  PRICING = 'pricing-v2',
  PRODUCT_LANDINGSPAGE = 'product-landingspage',
  ENERGY_GARDEN = 'energy-garden'
}
