import { Month, RegistrationSteps } from './types'
import { Product } from 'types/product-data'

export const ALLOWED_IBAN_COUNTRIES = ['BE', 'FR', 'DE', 'LU', 'NL']

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_FORMAT_REACT_DATEPICKER = 'dd/MM/yyyy'

export const DAYS_TO_YEARS_COEFFICIENT = 0.002738

export const REGISTRATION_FLOW = [
  RegistrationSteps.PERSONAL_DATA,
  RegistrationSteps.METER_DETAILS,
  RegistrationSteps.BILLING,
  RegistrationSteps.OVERVIEW,
  RegistrationSteps.CONFIRMATION
]

export const MIN_AGE = 18

export const MONTHS: Month[] = [
  {
    defaultValue: 'januari',
    i18nKey: 'january'
  },
  {
    defaultValue: 'februari',
    i18nKey: 'february'
  },
  {
    defaultValue: 'maart',
    i18nKey: 'march'
  },
  {
    defaultValue: 'april',
    i18nKey: 'april'
  },
  {
    defaultValue: 'mei',
    i18nKey: 'may'
  },
  {
    defaultValue: 'juni',
    i18nKey: 'june'
  },
  {
    defaultValue: 'juli',
    i18nKey: 'july'
  },
  {
    defaultValue: 'augustus',
    i18nKey: 'august'
  },
  {
    defaultValue: 'september',
    i18nKey: 'september'
  },
  {
    defaultValue: 'oktober',
    i18nKey: 'october'
  },
  {
    defaultValue: 'november',
    i18nKey: 'november'
  },
  {
    defaultValue: 'december',
    i18nKey: 'december'
  }
]

export const PREFERRED_PHONE_COUNTRIES = ['be', 'nl', 'fr', 'de', 'lu']

export const NORMAL_PROPOSITIONS_ORDER = [Product.VARIABLE_OFFLINE, Product.FIX, Product.VARIABLE_ONLINE]
export const FIXED_FIRST_PROPOSITIONS_ORDER = [Product.FIX, Product.VARIABLE_OFFLINE, Product.VARIABLE_ONLINE]
