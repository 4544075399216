import { ElectricityAndGasPrices } from 'features/simulation/types'
import { Product } from 'types/product-data'

export const BOLT_GO_PRICES: ElectricityAndGasPrices = {
  electricity: 1500,
  gas: 2500
}

export const BRUSSELS_AIRPORT_PRODUCER_SLUG = 'christel-rutger'
export const BRUSSELS_AIRPORT_PROMO_CODE = 'BRUSSELSAIRPORT'
export const BRUSSELS_AIRPORT_ZIP_CODES = ['1820', '1830', '1831', '1930', '1931', '3070']

export const DEFAULT_PRODUCTS: Product[] = [Product.VARIABLE_ONLINE, Product.VARIABLE_OFFLINE]
