import { ELProduct, NGProduct, Product } from 'types/product-data'
import { PromoCode } from 'types/promo-referral-codes'
import { SimulatedPropositions } from 'store/customer/types'

// TYPES

export type DeliveryPoint = {
  postalCode: string
  townCode: string
  townName: string
}

export type ElectricityAndGasPrices = {
  electricity: number
  gas: number
}

export type MeterUsage = {
  electricity: {
    consumption: {
      double?: {
        day: number
        night: number
      }
      exclusiveNight?: number
      single?: number
    }
    injection?: {
      double?: {
        day: number
        night: number
      }
      single?: number
    }
  }
  gas?: number
}

export type Price = {
  total: number
  totalWithoutVAT: number
  vat: number
  totalEnergy: number
  totalInjection?: number
  subscription: number
  transport: number
  discount: number
  injection?: number
}

export type Products = {
  electricity?: ELProduct
  gas?: NGProduct
}

export type SimulatePriceRequest = {
  counterType: CounterTypes
  deliveryPoint: DeliveryPoint
  discountCode?: PromoCode
  hasSolarPanels: boolean
  isCompany: boolean
  meterUsage: MeterUsage
  product: Products
  solarKva?: number
  walloniaSolarPanelsSince2024?: boolean
}

export type SimulatePriceResponse = {
  price: {
    total: Price
    electricity: Price
    gas?: Price
  }
}

export type KvaInjectionPayload = {
  counterType: CounterTypes
  hasSolarPanels: boolean
  walloniaSolarPanelsSince2024?: boolean
}

export type PropositionRequestOptions = {
  products?: Product[]
  onSuccess?: (availablePropositions: SimulatedPropositions) => void
  onError?: () => void
}

// ENUMS

export enum CounterTypes {
  ANALOG = 'Analog',
  DIGITAL = 'Digital'
}

export enum EnergySuppliers {
  ANTARGAZ = 'Antargaz',
  BOLT = 'Bolt',
  DATS24 = 'Dats 24',
  ECOPOWER = 'Ecopower',
  ELEGANT = 'Elegant',
  ENECO = 'Eneco',
  ENGIE = 'Engie',
  LUMINUS = 'Luminus',
  MEGA = 'Mega',
  OCTA_PLUS = 'Octa+',
  TOTAL_ENERGY = 'Total Energies',
  OTHER = 'Other'
}

export enum MeterType {
  SINGLE_RATE = 'Single',
  DOUBLE_RATE = 'Double'
}

export enum SimulationSteps {
  POSTAL_CODE = 'Postal Code',
  ENERGY_TYPE = 'Energy Type',
  USAGE = 'Usage',
  PROPOSITION = 'Proposition'
}
